













import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import login from "./login.vue";
import navbar from "./navbar.vue";
@Component({
  components: {
    navbar,
    login,
  },
})
export default class topnavbar extends Vue {
  isloginform: boolean = true;
  islogin: boolean = false;

  guanbilogin(data: boolean) {
    this.islogin = !this.islogin;
    this.isloginform = true;
  }
  singuplogin(data: boolean) {
    this.islogin = !this.islogin;
    this.isloginform = false;
  }
}
