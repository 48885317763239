





















































































import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import bottommenu from "../components/bottommenu.vue";
import topnavbar from "@/components/topnavbar.vue"; // @ is an alias to /src
import { warn } from "vue-class-component/lib/util";

@Component({
  components: {
    topnavbar,
    bottommenu,
  },
})
export default class Home extends Vue {
  count: number = 0;
  newcount: string = "新的值是";
  islogin: boolean = false;
  isloginform: boolean = true;
  @Watch("count")
  onChildChanged(val: string, oldVal: string) {
    this.newcount = this.newcount + val;
  }
  goto(n: number) {
    this.count++;
  }
  guanbilogin(data: boolean) {
    this.islogin = !this.islogin;
    this.isloginform = true;
  }
  singuplogin(data: boolean) {
    this.islogin = !this.islogin;
    this.isloginform = false;
  }
  Started() {
    this.$router.push({ name: "starting" });
  }
  created() {
    this.count++;
  }
}
