import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css/normalize.css";
import "./util/rem";
import VueCookies from "vue-cookies";
import ElementUI from "element-ui";

import { get, post, DELETE, put } from "./util/http";
import { api } from "./util/api";
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$DELETE = DELETE;
Vue.prototype.$put = put;
Vue.prototype.$api = api;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.use(VueI18n);
let i18n = {}
const curLang = "zh";


async function tempI118n(version: string) {
  i18n = new VueI18n({
    locale: curLang, //初始化,保证刷新页面也保留
    messages: {
      cn: await getTranslateData("cn", version),
      en: await getTranslateData("en", version),
      tw: await getTranslateData("tw", version),
      ja: await getTranslateData("ja", version),
    },
    silentTranslationWarn: true 
  });

  console.log(i18n)
  Vue.prototype._i18n = i18n;
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

async function getTranslateData(code: string, version: string) {
  let res = await get(
    api.getTranslateData + "?projectid=2&version=1.0.0",
    "",
    ""
  );
 
  let wrapObj:any = {};
  res.map((item:any) => {
    let childObj:any = {};
    item.children.map((item2:any ) => {
      childObj[item2.key] = item2[code];
    });
    wrapObj[item.key] = childObj;
    // debugger
  });
  
 
  return wrapObj;
}

declare module "Vue/types/vue" {
  interface Vue {
    $get: any;
    $api: any;
  }
}


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");