











import { Component, Emit, Prop, Vue } from "vue-property-decorator";
@Component
export default class bottommenu extends Vue {
  private goto(data: number) {
    switch (data) {
      case 1:
        this.$router.push({ path: "/Agreement?type=1" });
        break;

      case 2:
        this.$router.push({ path: "/Agreement?type=2" });
        break;
      case 3:
        this.$router.push({ name: "starting" });
        break;
      case 4:
        this.$router.push({ name: "studyrooapps" });
        break;
      default:
        break;
    }
  }
}
