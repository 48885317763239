<template>
  <div class="bg">
    <div class="content">
      <div class="formbox">
        <div class="switchbox">
          <div class="switch">
            <div
              class="btn"
              :class="switchindex === 1 ? 'active-btn ' : ''"
              @click="switchindex = 1"
            >
              Phone
            </div>
            <div
              class="btn"
              :class="switchindex === 0 ? 'active-btn ' : ''"
              @click="switchindex = 0"
            >
              Email
            </div>
          </div>
        </div>
        <div class="formcontentbox">
          <div id="login" v-if="isloginform">
            <div class="input-group">
              <div v-if="switchindex == 1">
                <div class="area-select">
                  <div class="phone-select">+61</div>
                  <el-input
                    v-model="user"
                    class="phoneuser"
                    placeholder="Phone number"
                  ></el-input>
                </div>
              </div>
              <div v-else>
                <div class="area-select">
                  <el-input
                    v-model="user"
                    class="phoneuser"
                    placeholder="Email"
                  ></el-input>
                </div>
              </div>
              <el-input
                placeholder="Password"
                v-model="password"
                class="passwordinput"
                show-password
              ></el-input>
            </div>
            <div class="forgetbox">
              <el-checkbox
                v-model="Keeplogin"
                class="forgetcheck"
                fill="#FFAB00"
                text-color="#000000"
                >Keep me logged in</el-checkbox
              >

              <!-- <div class="forgettext">Forgot password?</div> -->
            </div>
            <div class="loginbtn" @click="login">LOG IN</div>
            <div class="signup">
              No account yet?<span @click="isloginform = false">Sign up</span>
            </div>
          </div>
          <div id="signup" v-else>
            <div class="input-group">
              <div v-if="switchindex == 1">
                <div class="area-select">
                  <div class="phone-select">+61</div>
                  <el-input
                    v-model="user"
                    class="phoneuser"
                    placeholder="Phone number"
                  ></el-input>
                </div>
              </div>
              <div v-else>
                <div class="area-select">
                  <el-input
                    v-model="user"
                    class="phoneuser"
                    placeholder="Email"
                  ></el-input>
                </div>
              </div>
              <div class="area-select">
                <el-input
                  v-model="code"
                  class="phoneuser"
                  placeholder="Verification code"
                ></el-input>
                <div class="sendcode" @click="getcode" v-if="!issendcode">
                  SEND
                </div>
                <div class="Resend" v-if="resetTime != 0 && issendcode">
                  Resend in
                  <div>{{ resetTime }}</div>
                </div>
                <div
                  class="sendcode"
                  v-else-if="issendcode"
                  @click="getAuthCodeAgain"
                >
                  Resend
                </div>
              </div>
              <el-input
                placeholder="Password"
                v-model="password"
                class="passwordinput"
                show-password
              ></el-input>
              <el-input
                placeholder="Enter password again"
                v-model="enterpassword"
                class="passwordinput"
                show-password
              ></el-input>
            </div>

            <div class="loginbtn" @click="signup">SIGN UP</div>
            <div class="signup">
              Already have an account?<span @click="isloginform = true">
                Log in</span
              >
            </div>
          </div>

          <div class="aboutbox">
            By logging in or creating an account , you agree to Studyroo’s
            <span>Terms of Service</span> and <span>Privacy Policy.</span>
          </div>
        </div>

        <div class="iconfont icon-guanbi" @click="guanbilgin"></div>
      </div>
    </div>
  </div>
</template>

<script>
import mInput from "./mInput.vue";
import { loginMes } from "../util/global";
import { mapMutations, mapActions } from "vuex";
let timer = {};
export default {
  props: { isloginform: Boolean },
  data() {
    return {
      switchindex: 1,
      user: "", //账户
      password: "", // 密码
      Keeplogin: "",
      enterpassword: "",
      code: "",
      name: "",
      curAreaCellCode: "+61",
      ischecked: true,
      resetTime: 60,
      issendcode: false,
    };
  },
  methods: {
    ...mapMutations(["setUserMes"]),
    guanbilgin() {
      this.$emit("guanbilogin", false);
    },
    countdown() {
      // 验证码倒计时
      let that = this;
      clearInterval(this.timer);
      // 进入页面后就开始60秒倒数
      that.timer = setInterval(() => {
        that.resetTime--;
        if (that.resetTime == 0) {
          clearInterval(that.timer);
        }
      }, 1000);
    },
    getAuthCodeAgain() {
      // 重新获取验证码
      clearInterval(timer);
      let that = this;
      that.resetTime = 60;
      that.code = "";

      this.getCode(); // 获取验证码
      timer = setInterval(() => {
        that.resetTime = that.resetTime * 1 - 1;
        if (that.resetTime == 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
    getcode() {
      debugger;
      let userInfo;
      if (this.switchindex === 1) {
        userInfo = {
          value: this.curAreaCellCode + this.user,
          type: 0,
        };
      } else {
        userInfo = {
          value: this.user,
          type: 1,
        };
      }
      this.issendcode = true;
      this.$get(
        this.$api.getcode +
          "?value=" +
          userInfo.value +
          "&type=" +
          userInfo.type
      ).then((res) => {
        this.issendcode = true;
        console.log(res);
        this.countdown();
      });
    },
    login() {
      if (this.ischecked) {
        let type = this.switchindex ? 0 : 1;
        let that = this;
        let userInfo = {
          user: that.user,
          password: that.password,
          type: type,
        };

        if (this.switchindex == 1) {
          userInfo.user = this.curAreaCellCode + userInfo.user;
        }

        that.$post(that.$api.gettoken, userInfo).then((res) => {
          console.log(res);
          this.$cookies.set(loginMes, res);

          that.setUserMes();

          that.showLogin = true;
          this.$message({
            message: "登录成功",
            type: "success",
          });
          setTimeout(function () {
            that.$emit("guanbilogin", false);
            that.$router.push({ name: "Home" });
          }, 200);
        });
      }
    },
    async signup() {
      let that = this;
      let pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/; //8到16位数字与字母组合
      var a = pwdReg.test(this.password);
      debugger;
      if (that.switchindex == 1) {
        let coodbool = that.execuCull(that.code);
        if (coodbool) {
          if (this.password != this.enterpassword) {
            that.$message({
              message: "The two passwords are inconsistent.",
              type: "warning",
            });
          } else {
            if (!a) {
              that.$message({
                message:
                  "Passwords must be 8-16 characters and contain both numbers and letters characters.",
                type: "warning",
              });
            } else {
              let userInfo = {
                phoneNumber: that.user,
                password: that.password,
              };
              that
                .$post(
                  that.$api.registerteacher + "?code=" + that.code,
                  userInfo
                )
                .then((res) => {
                  console.log(res);
                  that.$cookies.set(loginMes, res);

                  that.setUserMes();

                  that.showLogin = true;
                  that.$message({
                    message: "登录成功",
                    type: "success",
                  });
                  setTimeout(function () {
                    that.$emit("guanbilogin", false);
                    that.$router.push({ name: "Home" });
                  }, 200);
                });
            }
          }
        }
      } else {
        let coodbool = await that.execuCull(that.code);
        debugger;
        if (coodbool) {
          debugger;
          if (that.password != that.enterpassword) {
            that.$message({
              message: "The two passwords are inconsistent.",
              type: "warning",
            });
          } else {
            if (!a) {
              that.$message({
                message:
                  "Passwords must be 8-16 characters and contain both numbers and letters characters.",
                type: "warning",
              });
            } else {
              debugger;
              let userInfo = {
                email: that.user,
                password: that.password,
              };
              that
                .$post(
                  that.$api.registerteacher + "?code=" + this.code,
                  userInfo
                )
                .then((res) => {
                  // console.log(res);
                  // this.$cookies.set(loginMes, res);

                  // that.setUserMes();

                  // that.showLogin = true;
                  // this.$message({
                  //   message: "登录成功",
                  //   type: "success",
                  // });
                  // setTimeout(function () {
                  //   that.$emit("guanbilogin", false);
                  //   that.$router.push({ name: "Home" });
                  // }, 200);

                  this.login();
                });
            }
          }
        }
      }
    },
    async execuCull(params) {
      // 验证码接口验证
      let that = this;
      let data;
      await this.$get(
        this.$api.checkcode + "?value=" + this.user + "&code=" + params
      ).then((res) => {
        debugger;
        data = res;
      });
      return data;

      // return new Promise((resolve, reject) => {
      //   this.$get(this.$api.checkcode+'?value='+this.user+'&code='+params)
      //     .then((res) => {
      //       that.showLogin = true;
      //       resolve(res);
      //     })
      //     .catch((err) => {
      //       that.showLogin = true;
      //       reject(err);
      //     });
      // });
    },
  },

  components: {
    mInput,
  },
};
</script>

<style scoped lang="scss">
.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 1000;
  .content {
    position: relative;
    width: 100%;
    height: 100%;
    .formbox {
      position: absolute;
      left: 50%;
      top: 15rem;
      transform: translateX(-50%);
      background: #f9f9f8;
      width: 24.6875rem;

      border-radius: 8px;
      .iconfont {
        left: 50%;
        transform: translateX(-50%);
        bottom: -3.75rem;
        position: absolute;
        color: #d3cfc8;
        font-size: 50px;
        &:hover {
          cursor: pointer;
        }
      }
      .switchbox {
        width: 100%;
        height: 4.6875rem;
        padding: 20px 2.5rem;
        background-color: #ffffff;
        border-radius: 8px;
        border-bottom: #f1f3f4 solid 1px;
        .switch {
          display: flex;
          height: 2.1875rem;
          justify-content: space-between;
          width: 100%;
          background-color: #f0f6fc;
          border-radius: 8px;

          .btn {
            width: 49%;
            height: 2.1875rem;
            border-radius: 8px;
            text-align: center;
            line-height: 2.1875rem;
            color: #999999;
            background-color: #f9f9fc;
            font-size: 12px;
            font-family: HelveticaNeue;
          }
          .active-btn {
            font-weight: 600;
            background-color: #ffab00;
            color: #ffffff;
            box-shadow: 3rpx 3rpx 10rpx rgba($color: #333333, $alpha: 0.1);
          }
        }
      }
      .formcontentbox {
        padding: 0 2.5rem;
        .area-select {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          border: 1px solid #dedede;
          border-radius: 10px;
          height: 2.875rem;
          overflow: hidden;
          background-color: #ffffff;
          .sendcode {
            color: #ffab00;
            height: 0.875rem;
            width: 70px;
            font-size: 12px;
            line-height: 0.875rem;
            font-weight: bold;
            margin: auto 0;
            &:hover {
              cursor: pointer;
            }
          }
          .Resend {
            color: #ffab00;
            height: 0.875rem;
            width: 70px;
            font-size: 0.75rem;
            text-align: center;
            margin: 0 0.3125rem;
            margin-top: 10px;
          }

          .phone-select {
            height: 0.875rem;
            width: 70px;
            line-height: 0.875rem;

            border-right: 1px #dedede solid;
            margin: auto 0;
          }
          .phoneuser {
            width: 100%;
            /deep/ .el-input__inner {
              height: 2.8125rem;
              line-height: 2.8125rem;
              border: 0px;
            }
          }
        }
        .passwordinput {
          margin-top: 20px;
          /deep/ .el-input__inner {
            border-radius: 10px;
            height: 2.875rem;
            border: 1px solid #dedede;
          }
        }
        .forgetbox {
          margin-top: 16px;
          width: 100%;

          display: flex;
          justify-content: space-between;
          color: #34365f;
          .forgettext {
            &:hover {
              cursor: pointer;
            }
          }
          .forgetcheck {
            /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inne {
              background-color: #ffab00;
              border-color: #ffab00;
            }
            /deep/ .el-checkbox__inner .el-checkbox__inner:hover {
              border-color: #ffab00;
            }
            /deep/ .el-checkbox__label {
              color: #34365f;
            }
            /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
              color: #34365f;
            }
          }
        }
        .loginbtn {
          margin-top: 2.5rem;
          width: 100%;
          height: 2.875rem;
          line-height: 2.875rem;
          color: #fff;
          background-color: #ffab00;
          border-radius: 10px;
          font-size: 12px;
          font-weight: bold;
          &:hover {
            cursor: pointer;
          }
        }
        .signup {
          font-size: 12px;
          margin-top: 15px;
          color: #b0b0b0;
          span {
            color: #34365f;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .aboutbox {
          font-size: 12px;
          margin-top: 41px;
          margin-bottom: 40px;
          color: #b0b0b0;
          span {
            text-decoration: underline;
            color: #34365f;
          }
        }
      }
    }
  }
}
</style>
