let api:any = {
   
    getTranslateData:'/translate/translate',//获取翻译数据
    gettoken: '/usc_user/login', // 登录
    getcode:'/check-code/code',//获取验证码
    checkcode:'/check-code/check-code',//验证验证码
    registerteacher:'/usc_user/registerteacher',//注册用户
    creatteacherterms:'/tc_teacherterms/create',//创建职业教师认证
    updateteacher:'/usc_user/updateuser',//修改教师信息
    addteachercontact:'/tc_communicationtool/create',//增加老师联系方式
    getdatabyteacherid:'/tc_teacherterms/getdatabyteacherid',//获取教师认证数据
    getteachercontact:'/tc_communicationtool/getdatalist',
    updateteachercontact:'/tc_communicationtool/update',
    getlesson:'/tc_course/getlessonmanagementdata',
    addcourse:'/tc_course/addcourse',
    updatelesson:'/tc_course/update',
    addlesson:'/tc_course/createnormallesson',
    deletecourse:'/tc_course/deletecourse',
    deletelesson:'/tc_course/delete',
    getdiclist:'/dataitem/getdataitemdetailslist',
    savealltime:'/tc_availability/saveall',
    getallteachertime:'/tc_availability/getallteachertime',
    getteacherinfo:'/usc_user/getinfo'

}




export {
    api
}