//-------------------------------------------------------------------------请求的封装
import { objUrl, loginMes } from "./global";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Vue from "vue";
import { Message } from 'element-ui';
//请求封装
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
        // 返回 401 清除token信息并跳转到登录页面
      }
    }
    return Promise.reject(error.response.data); // 返回接口返回的错误信息
  }
);
function request(url: string, method: any, data: object, mask: any) {
  return new Promise((resolve, reject) => {
    let token;
    let that=Vue;
    if (!!Vue.$cookies.get(loginMes)) {
      token = "Bearer " + Vue.$cookies.get(loginMes).token;
    }
    let type = "application/json";
    if (mask) {
      //传啦mask，表示需要加载loading
    }
    let confing: AxiosRequestConfig = {};

    if (url.split("?")[0] == "/translate/translate") {
      confing = {
        url: objUrl.fanyi + url,
        method: method,
        data: data,
        headers: {
          "content-type": "application/json", // 默认值
          Authorization: token,
        },
      };

      axios
        .request(confing)
        .then((res) => {
          if (res.data.statusCode == 200) {
            resolve(res.data.data);
          } else if (res.data.statusCode == 401) {
          } else {
            // console.log(Vue.prototype,'xxxx')
            console.log(res);
            Message.error({message:'123'})
            reject(res.data);
          }
        })
        .catch((res) => {});
    } else {
      confing = {
        url: objUrl.baseUrl + url,
        method: method,
        data: data,
        headers: {
          "content-type": "application/json", // 默认值
          Authorization: token,
        },
      };

      axios
        .request(confing)
        .then((res) => {
          if (res.data.code == 200) {
            resolve(res.data.data);
          } else if (res.data.code == 401) {
          } else {
            // console.log(Vue.prototype,'xxxx')
            console.log(res);
            Message.error({message:res.data.message})
            reject(res.data);
          }
        })
        .catch((res) => {});
    }
  });
}

export function get(url: string, data: any, mask: any): Promise<any> {
  return request(url, "GET", data, mask);
}
export function post(url: string, data: any, mask: any): Promise<any> {
  return request(url, "POST", data, mask);
}
export function put(url: string, data: any, mask: any): Promise<any> {
  return request(url, "PUT", data, mask);
}
export function DELETE(url: string, data: any, mask: any): Promise<any> {
  return request(url, "DELETE", data, mask);
}
