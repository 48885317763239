import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import profie from "../views/teachersetting/profile/profile.vue";
import Teacherintroduction from "../views/teachersetting/teacherintroduction/teacherintroduction.vue";
import { loginMes } from "../util/global";
import { Message } from "element-ui";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/teach",
    name: "teach",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teach.vue"),
  },
  {
    path: "/studyrooapps",
    name: "studyrooapps",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/studyrooapps.vue"),
  },
  {
    path: "/Becometeacher",
    name: "Becometeacher",
    meta: { isauto: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Becometeacher.vue"),
  },
  {
    path: "/starting",
    name: "starting",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/starting.vue"),
  },
  {
    path: "/teacherhome",
    name: "teacherhome",
    meta: { isauto: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teacherhome.vue"),
  },
  {
    path: "/Agreement",
    name: "Agreement",
    meta: { isauto: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Agreement.vue"),
  },
  {
    path: "/teachersetting",
    meta: { isauto: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/teachersetting/teachersetting.vue"
      ),
    children: [
      {
        path: "/teachersetting/profile",
        name: "profile",
        meta: { isauto: true },
        component: profie,
      },
      {
        path: "/teachersetting/teacherintroduction",
        name: "teacherintroduction",
        meta: { isauto: true },
        component: Teacherintroduction,
      },
      {
        path: "/teachersetting/videosetting",
        name: "videosetting",
        meta: { isauto: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/teachersetting/videosetting/videosetting.vue"
          ),
      },
      {
        path: "/teachersetting/lessonmanagement",
        name: "lessonmanagement",
        meta: { isauto: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/teachersetting/lessonmanagement/lessonmanagement.vue"
          ),
      },
      {
        path: "/teachersetting/AvailabilitySettings",
        name: "AvailabilitySettings",
        meta: { isauto: true },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/teachersetting/AvailabilitySettings/AvailabilitySettings.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// router.beforeEach((to, from, next) => {
// let  that=this;
//   if (to.meta.isauto) {
//     let data = Vue.$cookies.get(loginMes);
//     if (!Vue.$cookies.get(loginMes)) {
//       Message.error('Please log in first');
//       next("/");
//     }
//   }
//   next();
// });
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});

export default router;
