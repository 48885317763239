
















































































































































































































































































import { Component, Emit, Vue } from "vue-property-decorator";
import { State, Action, Mutation, Getter } from "vuex-class";
import { objUrl } from "@/util/global";
@Component({})
export default class profile extends Vue {
  @Getter("getUserMes") getUserMes: any;
  @Mutation("setChangeUserMes") setChangeUserMes: any;
  private baseurl = objUrl.baseUrl;
  created() {
    this.getlianxifangshi();
    this.getLXGJ();
    this.DisplayName = this.getUserMes.disPlayerName;
    this.fromcountry = this.getUserMes.from;
    this.fromprovince = this.getUserMes.from1;
    this.livingincountry = this.getUserMes.livingIn;
    this.livinginprovince = this.getUserMes.livingIn1;
    this.FirstName = this.getUserMes.firstName;
    this.LastName = this.getUserMes.lastName;
    this.Gender = this.getUserMes.gender == 0 ? "male" : "female";
    this.imgurl = this.getUserMes.headImageUrl;
    this.showimgurl = objUrl.baseImgUrl + this.getUserMes.headImageUrl;
    this.Address = this.getUserMes.address;
    this.Year = this.getUserMes.birthDate.split("-")[0];
    this.Month = this.getUserMes.birthDate.split("-")[1];
    this.Day = this.getUserMes.birthDate.split("-")[2].split(" ")[0];
  }
  //#region  data
  private DisplayName = "";
  private value = "";
  private checked = true;
  private options = [];
  private optionss = [
    {
      value: "0",
      label: "Zoom",
    },
  ];
  private country = [
    {
      value: "6177d3c9-ac81-6b94-006e-149e6ad29201",
      label: "Australia",
    },
  ];
  private province = [
    {
      value: "6177d445-ac81-6b94-006e-14a000d1ac19",
      label: "New South Wales",
    },
    {
      value: "6177d44f-ac81-6b94-006e-14a14070c0a2",
      label: "Queensland",
    },
    {
      value: "6177d458-ac81-6b94-006e-14a27a5af7e6",
      label: "South Australia",
    },
    {
      value: "6177d45e-ac81-6b94-006e-14a3323a8179",
      label: "Victoria",
    },
    {
      value: "6177d46b-ac81-6b94-006e-14a45105c27b",
      label: "Western Australia",
    },
    {
      value: "6177d478-ac81-6b94-006e-14a52e165769",
      label: "Tasmania",
    },
    {
      value: "6177d47f-ac81-6b94-006e-14a6798c1c29",
      label: "Northern Territory",
    },
    {
      value: "6177d488-ac81-6b94-006e-14a751c5f9ac",
      label: "Australian National Territory",
    },
  ];
  private MeetingLink = "";
  private MeetingID = "";
  private Passcode = "";
  private fromcountry = "";
  private fromprovince = "";
  private livingincountry = "";
  private livinginprovince = "";
  private FirstName = "";
  private LastName = "";
  private Gender = "";
  private Address = "";
  private imgurl = "";
  private showimgurl = "";
  private Year = "";
  private Month = "";
  private Day = "";
  private gender = [
    {
      value: 0,
      label: "male",
    },
    {
      value: 1,
      label: "female",
    },
  ];
  private date = { year: [], month: [], day: [] };
  private appendobj = [];
  //#endregion

  private uplodsuccess(response, file, fileList) {
    debugger;
    this.imgurl = response.data;
    this.showimgurl = objUrl.baseImgUrl + response.data;
    console.log(objUrl.baseImgUrl + response.data);
    console.log(file);
    console.log(fileList);
  }
  private uplodvedioprogress(event, file, fileList) {
    let size = event.size / 1024 / 1024;
    if (size > 4) {
      return;
    }
    debugger;
  }
  private addappendobj() {
    this.appendobj.push({ value: "", code: "" });
    console.log(this.appendobj);
  }
  private shanchuappendobj(index: number) {
    this.appendobj.splice(index, 1);
  }
  private monthchange() {
    this.getDay();
  }

  private addteachercontact() {
    let that = this;
    let userdata = {
      value: this.value,
      MeetingLink: this.MeetingLink,
      MeetingID: this.MeetingID,
      Passcode: this.Passcode,
    };
    let data = {
      dataJson: JSON.stringify({
        zoomdata: userdata,
        appenddata: this.appendobj,
      }),
    };
    debugger;
    that
      .$put(that.$api.updateteachercontact + "/" + this.getUserMes.id, data)
      .then((res) => {
        console.log(res);
      });
  }
  private getlianxifangshi() {
    this.$get(this.$api.getteachercontact + "/" + this.getUserMes.id).then(
      (res) => {
        console.log(res);

        // let data = JSON.parse(res.dataJson);

        // this.value = data.value;
        // this.MeetingLink = data.MeetingLink;
        // this.MeetingID = data.MeetingID;
        // this.Passcode = data.Passcode;
        let data = JSON.parse(res.dataJson);
        this.appendobj = data.appenddata;
        this.value = data.zoomdata.value;
        this.MeetingLink = data.zoomdata.MeetingLink;
        this.MeetingID = data.zoomdata.MeetingID;
        this.Passcode = data.zoomdata.Passcode;
      }
    );
  }
  private getLXGJ() {
    this.$get(this.$api.getdiclist + "?itemcode=LXGJ").then((res) => {
      this.options = res;
    });
  }
  private updateteacher() {
    debugger;
    let userdata = {
      id: this.getUserMes.id,

      disPlayerName: this.DisplayName,
      from: this.fromcountry,
      from1: this.fromprovince,
      livingIn: this.livingincountry,
      livingIn1: this.livinginprovince,
      lastName: this.LastName,
      firstName: this.FirstName,
      birthDate:
        this.Year +
        "-" +
        this.padding0(this.Month, 2) +
        "-" +
        this.padding0(this.Day, 2),
      gender: this.Gender,
      address: this.Address,
      headImageUrl: this.imgurl,
    };
    this.addteachercontact();
    this.$put(this.$api.updateteacher, userdata).then((res) => {
      this.setChangeUserMes(res);
    });
  }

  //#region  date
  init() {
    this.getYear();
    this.getMonth();
    this.getDay();
  }
  getYear() {
    //获取年
    let date = new Date();
    let current_year = date.getFullYear();
    for (let i = 0; i < 100; i++) {
      let y = current_year - i;
      this.date.year.push({ value: y, label: y });
    }
  }
  getMonth() {
    //获取月
    for (let i = 1; i < 13; i++) {
      this.date.month.push({ value: i, label: i });
    }
  }
  getDay() {
    //获取日

    let day = this.getDaysInMonth(this.Year, this.Month);
    this.date.day = [];
    for (let i = 1; i <= day; i++) {
      this.date.day.push({ value: i, label: i });
    }
  }
  getDaysInMonth(year, month) {
    //获取某个月的天数
    month = parseInt(month, 10);
    let d = new Date(year, month, 0);
    return d.getDate();
  }
  padding0(num, length) {
    //数字前填充0方法
    for (let len = (num + "").length; len < length; len = num.length) {
      num = "0" + num;
    }
    return num;
  }

  //#endregion
  mounted() {
    this.init();
  }
}
