

























































import { Component, Emit, Vue } from "vue-property-decorator";
import { State, Action, Mutation, Getter } from "vuex-class";
@Component({})
export default class teacherintroduction extends Vue {
  @Getter("getUserMes") getUserMes: any;
  @Mutation("setChangeUserMes") setChangeUserMes: any;
  private checkListdata = [];
  private textarea1 = "";
  private textarea2 = "";
  created() {
    this.textarea1 = this.getUserMes.abountMe;
    this.textarea2 = this.getUserMes.lessonsTeachingStyleContent;
    let str = this.getUserMes.lessonsTeachingStyleType.split(",");
    console.log(str);
    this.checkListdata;
    for (let index = 0; index < str.length; index++) {
      if (str[index] !== "") {
        this.checkListdata.push(str[index]);
      }
    }
  }

  private updateteacher() {
    let element = "";
    for (let index = 0; index < this.checkListdata.length; index++) {
      debugger;
      if (index == this.checkListdata.length - 1) {
        element = element + this.checkListdata[index];
      } else {
        element = element + this.checkListdata[index] + ",";
      }
    }
    let userdata = {
      id: this.getUserMes.id,

      abountMe: this.textarea1,
      lessonsTeachingStyleContent: this.textarea2,
      lessonsTeachingStyleType: element,
    };
    debugger;
    this.$put(this.$api.updateteacher, userdata).then((res) => {
      this.setChangeUserMes(res);
    });
  }
}
