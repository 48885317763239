












import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { component } from "vue/types/umd";
import { State, Action, Mutation, Getter } from "vuex-class";
import { loginMes } from "../util/global";
@Component
export default class navbar extends Vue {
  @Prop() private loginbool!: boolean;
  @Mutation("clearUserMes") clearUserMes: any;
  @Getter("getUserMes") getUserMes: any;
  @Emit("login")
  private login() {
    return !this.loginbool;
  }
  @Emit("signup")
  private signup() {
    return !this.loginbool;
  }

  created() {
    console.log(this.getUserMes);
  }

  private LOGOUT() {
    this.clearUserMes();
    this.$cookies.remove(loginMes);
    this.$router.push({ name: "Home" });
  }
  private goto() {
    // debugger;
    // if (this.getUserMes == null ? false : this.getUserMes.zyzg) {
    //   this.$router.push({ name: "teacherhome" });
    // } else {
    //   this.$router.push({ name: "starting" });
    // }
    window.location.href = 'https://admin.newachievement.com.au/';
  }
  private gohome() {
    this.$router.push({ name: "Home" });
  }
}
